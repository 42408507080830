import React, { useRef, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import CMI from '../Assest/Herslider/favicon.ico.jpg';
import { Link } from 'react-router-dom';

const Contact = () => {
  const form = useRef();
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[A-Za-z]+(?: [A-Za-z]+)?$/;
    const phoneRegex = /^(\+?\d{1,4}[-\s]?)?(\(?\d{3}\)?[-\s]?)?\d{3}[-\s]?\d{4}$/; // Updated regex pattern
  
    // Validate email
    if (!form.current.user_email.value.trim() || !emailRegex.test(form.current.user_email.value.trim())) {
      errors.email = 'Please enter a valid email address';
    }
  
    // Validate phone number
    if (!form.current.user_number.value.trim() || !phoneRegex.test(form.current.user_number.value.trim())) {
      errors.number = 'Please enter a valid phone number';
    }
  
    // Validate company name
    if (!form.current.user_company.value.trim()) {
      errors.company = 'Please enter your company name';
    }
  
    // Validate work needed
    if (!form.current.user_description.value.trim()) {
      errors.description = 'Please describe the work needed';
    }
  
    // Validate user name
    if (!form.current.user_name.value.trim() || !nameRegex.test(form.current.user_name.value.trim())) {
      errors.name = 'Please enter a valid name';
    }
  
    setValidationErrors(errors);
  
    // Return true if there are no validation errors
    return Object.keys(errors).length === 0;
  };
  

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop form submission if validation fails
    }

    console.log('Form submitted. Sending email...');

    // Send email using emailjs
    emailjs.sendForm('service_x65vcbn', 'template_nt02zwe', form.current, {
      publicKey: 'CqjL5tVolY4UcgaaX',
    })
      .then((res) => {
        console.log('SUCCESS!', res);
        form.current.reset();
        if (window.innerWidth <= 768) {
          // alert('Email sent successfully! We will be contacting you soon!');
        }
        navigate('/thanks');
      })
      .catch((error) => {
        console.log('FAILED...', error.text);
        alert('Failed to send email! Please ensure all information is entered correctly!');
      });
  };

  return (
    <div className='font-link border-white shadow-md rounded-lg p-[4%] lg:p-[4%] m-8 text-black bg-white'>
      <Fade>
        <div className='mx-auto grid gap-6 md:grid-cols-2'>
          <div className='my-3'>
            <h1 className='p-[4%] md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Ready to Solve Your Cleaning Challenges?</h1>
            <br></br>
            <p className=' lg:mt-8 p-[4%] text-xl'>
              <span className='font-semibold text-[#e37200]'>Let's start the conversation </span>
               and find the perfect cleaning solution for you. Fill out the form below to get started on your personalized cleaning plan!
            </p>
            <div className=' lg:flex lg:justify-center lg:mt-10 lg:p-[4%] '>
             <Link to='/'><img
             src={CMI} alt='CMI'
             className='hidden lg:flex lg:justify-center h-[10rem] w-[18rem] '
             /></Link> 
            
             </div>
          </div>
          <div className='my-4'>
            <form ref={form} onSubmit={sendEmail}>
              <input
                className={`border-[2px] border-black rounded-md p-3 my-2 w-full text-black ${
                  validationErrors.email && 'border-red-500'
                }`}
                type='email'
                name='user_email'
                placeholder='Enter Email'
              />
              {validationErrors.email && <p className='text-red-500'>{validationErrors.email}</p>}
              <input
                className={`border-[2px] border-black rounded-md p-3 my-2 w-full text-black ${
                  validationErrors.number && 'border-red-500'
                }`}
                type='text'
                name='user_number'
                placeholder='Enter Phone Number'
              />
              {validationErrors.number && <p className='text-red-500'>{validationErrors.number}</p>}
              <input
                className={`border-[2px] border-black rounded-md p-3 my-2 w-full text-black ${
                  validationErrors.company && 'border-red-500'
                }`}
                type='text'
                name='user_company'
                placeholder='Company Name'
              />
              {validationErrors.company && <p className='text-red-500'>{validationErrors.company}</p>}
              <textarea
                className={`border-[2px] border-black rounded-md p-3 my-2 w-full text-black ${
                  validationErrors.description && 'border-red-500'
                }`}
                name='user_description'
                rows='4'
                placeholder='Work Needed'
              ></textarea>
              {validationErrors.description && <p className='text-red-500'>{validationErrors.description}</p>}
              <div className='flex flex-col md:flex-row items-stretch w-full'>
                <input
                  className={`border-[2px] border-[#003c7a] rounded-md p-3 my-2 flex-1 md:mr-2 text-black ${
                    validationErrors.name && 'border-red-500'
                  }`}
                  type='text'
                  name='user_name'
                  placeholder='Your Name'
                />
                {validationErrors.name && <p className='text-red-500'>{validationErrors.name}</p>}
                <input
                  type='submit'
                  value='Request Estimate'
                  className='text-white bg-[#e37200] w-full md:w-[200px] rounded-md font-medium text-sm md:ml-2 mt-2 md:mt-0 px-6 py-3 hover:bg-[#003c7a] typ'
                />
              </div>
            </form>
            <br></br>
            <p className='mt-2'>
              We care about the protection of your data.
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Contact;
