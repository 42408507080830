import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Boeing from '../Assest/Clslider/Boeing.png';
import Milliken from '../Assest/Clslider/milliken.png';
import Ofs from '../Assest/Clslider/ofs.jpg';
import Dare from '../Assest/Clslider/dare.jpg';
import Schneider from '../Assest/Clslider/schneider.png';
import LMC from '../Assest/Clslider/lexingtonmedical_logo.jpg';
import Budd from '../Assest/Clslider/budd.jpg'
import Abm from '../Assest/Clslider/abm.png'
import Arco from '../Assest/Clslider/OIP.jpg'
import Alston from '../Assest/Clslider/alston.jpg'
import Pfg from '../Assest/Clslider/pfg-logo.png'
import Sizemore from '../Assest/Clslider/sizemore.jpg'
import Brass from '../Assest/Clslider/Brass.jpg'

function Clients() {
    const [settings] = useState({
        dots: false,
        fade: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2000,
        cssEase: ' ease-in-out',
        centerMode: true,
        centerPadding: '0',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    const logos = [
        { src: Boeing, link: 'https://www.boeing.com/' },
        { src: Milliken, link: 'https://www.milliken.com/en-my/' },
        { src: Ofs, link: 'https://www.ofsoptics.com/about/' },
        { src: Dare, link: 'https://www.darefoods.com/' },
        { src: Schneider, link: 'https://www.se.com/ww/en/' },
        { src: LMC, link: 'https://www.lexmed.com/' },
        { src: Abm, link: 'https://www.abm.com/' },
        { src: Alston, link: 'https://alstonco.com/' },
        { src: Sizemore, link: 'https://www.sizemoreinc.com/Janitorial' },
        { src: Pfg, link: 'https://www.pfgc.com/' },
        { src: Budd, link: 'https://www.buddgroup.com/' },
        { src: Arco, link: 'https://arcoconstruction.com/' },
        { src: Brass, link: 'https://www.brasfieldgorrie.com/' },
    ];

    return (
        <div className='p-[4%] lg:p-[4%] rounded-lg'>
            <Slider {...settings}>
                {logos.map((logo, index) => (
                    <div key={index} className='container'>
                        <a href={logo.link} target="_blank" rel="noopener noreferrer">
                            <img src={logo.src} alt="logos" className='w-40 h-40 my-4 mx-auto' />
                        </a>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default Clients;
