import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Slider from 'react-slick';
import First from '../Assest/Testslider/first.png';
import Truck from '../Assest//Testslider/truck.png';
import Forth from '../Assest/Testslider/forth.png';
import { Link } from 'react-router-dom';
import { HiChevronDoubleRight  } from "react-icons/hi";

function Testimonials() {

  

  const settings = {
    dots: true,
    fade: true,
    infinite: false,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const textSliderSettings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    cssEase: 'ease-in-out',
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Fade cascade delay={0.2}>
      <div>
      <div className='font-link bg-white border-white rounded-lg m-8 p-[6%] lg:p-[4%] shadow-md'>
        <div className='my-3'>
          <h1 className='text-[#e37200] text-center text-4xl pb-3 font-bold underline underline-offset-8 decoration-4'>TRUSTED WORK</h1>
        </div>
        <div className='mx-auto flex flex-wrap items-center'>
          <div className='w-full md:w-1/2 mb-2'>
            <Slider className='my-2' {...settings}>
              <div><img className='w-full md:w-[800px] mx-auto rounded-md' src={First} alt='First' /></div>
              <div><img className='w-full md:w-[800px] mx-auto rounded-md' src={Truck} alt='Truck' /></div>
              <div><img className='w-full md:w-[800px] mx-auto rounded-md' src={Forth} alt='Forth' /></div>
            </Slider>
          </div>
          <div className='w-full md:w-1/2 lg:pl-6'>
            
            <div className='flex flex-col justify-center mx-auto'>
              <div className='md-text-4xl sm-text-3xl text-lg py-2 lg:mb-8 '>
                <Slider className='mb-4' {...textSliderSettings}>
                <div><p className='text-center'>“Clean Metro brought in their crew and did a great job. <span className='font-semibold'>Very quick and professional.</span> I would recommend their service again and again.” – <span className='font-semibold'>Will D., Project Manager</span></p></div>
                <div><p className='text-center'>“It’s rare that you come across an exceptional commercial cleaning group like Clean Metro, Inc. (CMI)… <span className='font-semibold'>CMI would be a great asset</span> to any project. – <span className='font-semibold'>Watson H., Project Manager</span></p></div>
                <div><p className='text-center'>“… Crew is very well trained and gets the job done <span className='font-semibold'>effectively and efficiently.</span> I have personally recommended (CMI) to others and will continue.” - <span className='font-semibold'> Tommy L., Project Superintendent </span></p></div>
                </Slider>
              </div>
              <Fade>
              <div className=' bg-[#F2F3F4] rounded-md shadow-lg'>
              <div className=' my-2'>
              <div className='flex flex-col my-2'>
               <Link to='/about'>
                 <div className='flex items-center  hover:text-[#0066cc] '>
                  <Fade cascade >
                  <HiChevronDoubleRight className=' text-orange-500 text-4xl' style={{verticalAlign:'middle'}}/>
                    <p className=' font-bold ml-2'>MORE ABOUT US</p>
                  </Fade>
                 </div>
               </Link>
               </div>
               <Link to='/service'>
                 <div className='flex items-center hover:text-[#0066cc]'>
                  <Fade cascade >
                  <HiChevronDoubleRight className=' text-orange-500 text-4xl' style={{verticalAlign:'middle'}}/>
                    <p className=' font-bold ml-2'>VIEW OUR SERVICES</p>
                  </Fade>
                 </div>
               </Link>
               </div>
               </div>
               </Fade>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default Testimonials;
