import React from 'react'

import Contact from './components/Contact';
import Footer from './components/Footer';
import Home from './components/Home';
import Testimonials from './components/Testimonials';
import Offer from './components/Offer';
import Companies from './components/Companies';
import Cmi from './components/Cmi';
import Hire from './components/Hire'

function App() {
  return (
    <div>
      <Home/> 
      <Testimonials/>
      <Offer/>
      <Cmi/>
      <Companies/>
      <Hire/>
      <Contact/>
      <Footer />
   </div>
   
   
  
  );
}

export default App;
