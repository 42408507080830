import React from 'react'
import Clients from './Clients'
import {Fade} from 'react-awesome-reveal'

function Companies() {
  return (
    <Fade cascade delay={5}>
    <div className='font-link bg-white border-white rounded-lg m-8 py-4 px-6 shadow-md'>
    <div className=' my-3'>
      <h1 className=' text-[#e37200] flex justify-center text-4xl font-bold pb-5 underline underline-offset-8'>OUR WORK SITES</h1>
    </div>
    
    <div className=' shadow-lgpy-3'>
      <Clients/>
    </div>
  </div>
  </Fade>
  )
}

export default Companies