import React from 'react'
import { FaThumbsUp, FaBiohazard, FaHandshake } from 'react-icons/fa'

import {Fade, Index} from "react-awesome-reveal"

function Offer() {
  return (
    <div className='font-link w-full py-[5rem] px-4  rounded-lg '>
      <Fade cascade
          >
        <div className='  max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
            <div className=' bg-gray-50 w-full shadow-xl flex flex-col p-[6%] lg:p-[4%] my-4 rounded-lg hover:scale-105 duration-300'> 
                 <FaThumbsUp className='w-20 mx-auto mt-[-3rem] text-6xl  text-[#e37200]' />
                 <h2 className='text-2xl font-bold text-center py-8 text-[#05305e]'> PROFESSIONALISM </h2>
                 
                 <div className='  border-t border-[#003c7a] text-center font-medium'>
                    <p className='  mx-8 mt-6'><span className=' font-semibold'>Clean Metro, Inc.</span> creates technical cleaning 
                    solutions for facilities in <span className=' font-semibold'>South Carolina, Georgia, North Carolina, and Tennessee</span>.</p>
                    <p>As a result, we offer
                     <span className=' font-semibold  text-[#e37200]'> industrial maintenance cleaning and restoration.</span> For example, we target automotive, aerospace, textile and machine manufacturing. We are the preferred choice for commercial & industrial 
                    complete construction cleaning as well as final clean.</p>
                 </div>
                 
            </div>
            <div className='p-[6%] lg:p-[4%] bg-gray-50 w-full shadow-xl flex flex-col  md:my-0 my-8 rounded-lg hover:scale-105 duration-300'> 
                 <FaHandshake className='w-20 mx-auto mt-[-3rem] text-6xl text-[#e37200]' />
                 <h2 className='text-2xl font-bold text-center py-8 text-[#05305e]'>INNOVATIVE</h2>
               
                 <div className='  border-y border-[#003c7a] text-center font-medium'>
                    <p className='py-2  mx-8 mt-6'>While deep cleaning is a part of our repertoire,<span className=' font-semibold  text-[#e37200] '> we’re dedicated to environmentally friendly processes</span>. 
                    Employing methods like dry-ice blasting, glass bead blasting, compressed air, and deionized water, we prioritize eco-conscious cleaning solutions, 
                    often relying on the simplicity of water itself.</p> <p className=' pb-1'>Innovation defines us as <span className='font-semibold'>we tackle intricate and unconventional cleaning tasks, pioneering creative solutions</span> that set us apart.
                    </p>
                    
                 </div>
                
            </div>
            <div className=' bg-gray-50 w-full shadow-xl flex flex-col p-[6%] lg:p-[4%] my-4 rounded-lg hover:scale-105 duration-300'> 
                 <FaBiohazard className='w-20 mx-auto mt-[-3rem] text-6xl z-10  text-[#e37200]' />
                 <h2 className='text-2xl font-bold text-center py-8 text-[#05305e]'> SAFETY FIRST </h2>
                 
                 <div className=' border-t border-[#003c7a] text-center font-medium'>
                    <p className='mx-8 mt-6'>Safety remains paramount.<span className=' font-semibold  '> Our impeccable safety record is a 
                    testament to our unwavering vigilance</span>. Our utmost priorities encompass our team’s well-being and the safeguarding of your property. </p> 
                    <p>Our field support team holds <span className=' font-semibold  text-[#e37200]'> OSHA 10 certifications, </span> 
                    and our field management <span className=' font-semibold  text-[#e37200]'> team members are certified at the OSHA 30 level.</span>
                    </p>
                 </div>
            </div>
        </div>
        </Fade>
    </div>
  )
}

export default Offer