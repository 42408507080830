import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Contact from '../components/Contact'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Ga,Gb,Gc} from '../Assest/Aboutimg'
import Cmi from '../components/Cmi';
import { Fade } from 'react-awesome-reveal';


function About() {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: 'ease-in-out',
    slideToShow: 1,
    slideToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    
<div className='bg-[#F5F5F7]'>
     <div className='bg-[#05305e] z-10 '>
        <Navbar />
    </div>
<Fade>
     
        

  <div className='bg-[#F2F3F4] p-[6%] lg:p-[4%]'><Contact/></div>
  <div>
  <Footer/>
  </div>
  </Fade>  
</div>
  )
}

export default About