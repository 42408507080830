import React from 'react';
import Banner1 from '../Assest/Banners/Banner1.jpg';
import Banner2 from '../Assest/Banners/Banner2.jpg';
import { Fade } from 'react-awesome-reveal';

function Hire() {
  return (
    <div>
      <Fade cascade delay={5}>
        <div className='font-link bg-white border-white rounded-lg m-8 py-4 px-6 shadow-md'>
          <div className='my-3'>
            <h1 className='text-[#e37200] flex justify-center text-4xl font-bold pb-5 underline underline-offset-8'>WANT TO HIRE US?</h1>
          </div>

          <div className='shadow-lg py-3'>
            <div className='p-[4%] lg:p-[2%] mt-2 flex flex-col lg:flex-row lg:items-center'>
              <div className="lg:w-1/2 order-1 lg:order-1">
                <img className='rounded-md w-full h-auto max-w-3xl mb-4 lg:max-w-[70%] lg:mx-auto lg:mb-0' src={Banner1} alt='Banner 1' />
              </div>
              <div className="lg:w-1/2 order-2 lg:order-2">
                <img className='rounded-md w-full h-auto max-w-3xl mb-4 lg:max-w-[70%] lg:mx-auto lg:mb-0' src={Banner2} alt='Banner2' />
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default Hire;
