import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Backdrop from '../Assest/Banners/Backdropnew.jpg'


const Thanks = () => {
  return (
    
    <div className=' content-center text-center font-link border-white shadow-lg  rounded-lg text-black bg-white'>
        <div className='bg-[#05305e] relative z-10'>
        <Navbar />
      </div>
      <Fade>
        <div className='text-center mx-auto grid gap-6 '>
          <div className=' p-[6%] lg:p-[4%] my-3'>
            <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Thank You!</h1>
            <div className='text-2xl'>
            <p>We appreciate your interest in Clean Metro Inc's services. Your message has been successfully submitted.</p>
            <p>We will review your request and get back to you as soon as possible.</p>
            </div>
            <br />
            <br></br>
            <div className='p-[4%] lg:p-[2%] flex flex-col items-center'>
        <div className="aspect-w-16 aspect-h-9 ">
           <img className=' rounded-md w-full h-auto max-w-3xl  lg:mx-[2%] mb-4' src={Backdrop} alt='Backdrop' /> 
       </div>
       </div>
       <br></br>

            <div><p className='text-center text-2xl text-white rounded-md p-[6%] lg:p-[4%] shadow-lg  bg-[#05305e] '>“It’s rare that you come across an exceptional commercial cleaning group like Clean Metro, Inc. (CMI)… <span className='font-semibold'>CMI would be a great asset</span> to any project. – <span className='font-semibold'>Watson H., Project Manager</span></p></div>
            <br></br>
            <div className='text-center'><Link to='/'><button className=' font-link text-white text-lg bg-[#e37200] w-[200px] rounded-md font-bold my-6 py-3 hover:bg-[#05305e]'>Return to Home</button></Link></div>
            
          </div>
        </div>
      </Fade>
      <Footer/>
    </div>
  );
};

export default Thanks;
