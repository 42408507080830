import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Ga,Gb,Gc} from '../Assest/Aboutimg'
import Cmi from '../components/Cmi';
import { Fade } from 'react-awesome-reveal';

function About() {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: 'ease-in-out',
    slideToShow: 1,
    slideToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    
<div className='bg-[#F5F5F7]'>
     <div className='bg-[#05305e] z-10 '>
        <Navbar />
    </div>
<Fade>
     
        
 <div className='p-[4%] lg:p-[2%] flex flex-col lg:flex-row lg:items-center'>
  <div className="lg:w-1/2 order-2 lg:order-1">
    <h1 className='text-[#e37200] text-center lg:text-center text-4xl pb-3 font-bold underline underline-offset-4'>Who We Are</h1>
    <div className='mt-8'>
      {/* <ul className='flex-row items-center list-disc list-inside font-semibold text-3xl' style={{ color: '#05305e' }}>
        <li>Industry leader in technical cleaning</li>
        <li>Specialized team for out-of-scope projects</li>
        <li>Expertise in overhead cleaning, combustible dust containment, and more.</li>
      </ul> */}
      <p className='text-3xl leading-relaxed mt-4'>
      <span className='text-black font-bold'>Based in South Carolina, Clean Metro, Inc. operates as a specialized cleaning company.</span> Our expertise encompasses janitorial, construction cleaning, and specialty cleaning preventative maintenance solutions.</p> 
      <p className='text-3xl leading-relaxed mt-4'>We are designed to handle <span className='text-black font-bold'>large-scale commercial, industrial, and manufacturing facilities.</span> 
       Our services are tailored to these sectors, so we do not engage in residential or small commercial cleaning. 
      </p>
    </div>
  </div>
  <div className="lg:w-1/2 order-1 lg:order-2">
    <img className=' rounded-md w-full h-auto max-w-3xl lg:mx-[2%] mb-4 ' src={Ga} alt='Testimonial 2' />
  </div>
  </div>


<div className='p-[4%] lg:p-[2%] mt-2 flex flex-col lg:flex-row lg:items-center'>           
  <div className="lg:w-1/2 order-2 lg:order-2">
    <h1 className='text-[#e37200] text-center lg:text-center text-4xl pb-3 font-bold underline underline-offset-4'>Our Core Philosophy</h1>
    <div className='mt-8 lg:ml-6'>
      {/* <ul className='flex-row items-center list-disc list-inside font-semibold text-3xl' style={{ color: '#05305e' }}>
        <li>Industry leader in technical cleaning</li>
        <li>Specialized team for out-of-scope projects</li>
        <li>Expertise in overhead cleaning, combustible dust containment, and more.</li>
      </ul> */}
      <p className='text-3xl leading-relaxed mt-4'>
      At Clean Metro, Inc., we’re more than a cleaning company; <span className='text-black font-bold'>we’re trailblazers in the industry’s transition to the gig economy.</span>  Our extensive labor network empowers us to deliver services throughout the southeastern region and beyond, harnessing the power of the gig economy’s flexibility. 
     
      </p>
    </div>
  </div>
  <div className="lg:w-1/2 order-1 lg:order-1">
    <img className=' rounded-md w-full h-auto max-w-3xl  mb-4' src={Gb} alt='Testimonial 2' />
  </div>
</div>


 <div className='p-[4%] lg:p-[2%] flex flex-col lg:flex-row lg:items-center'>
  <div className="lg:w-1/2 order-2 lg:order-1">
    <h1 className='text-[#e37200] text-center lg:text-center text-4xl pb-3 font-bold underline underline-offset-4'>Why Choose Clean Metro Inc</h1>
    <div className='mt-8'>
      {/* <ul className='flex-row items-center list-disc list-inside font-semibold text-3xl' style={{ color: '#05305e' }}>
        <li>Experienced and Efficient Team</li>
        <li>Special</li>
        <li>Expertise in overhead cleaning, combustible dust containment, and more.</li>
      </ul> */}
      <p className='text-3xl leading-relaxed mt-4'>
      Clean Metro, Inc. sets the gold standard for commercial cleaning services. <span className='text-black font-bold'>With our unwavering commitment to excellence, we deliver unparalleled results for every client.</span> Whether it's comprehensive facility cleaning, specialized solutions for high-risk environments, or meticulous machine cleaning and degreasing, our skilled team ensures your premises are not just cleaned, but restored to pristine condition. 
      </p><p className='text-3xl leading-relaxed mt-4'>Backed by years of expertise and cutting-edge techniques, <span className='font-bold text-black'>Clean Metro Inc is your trusted partner for maintaining a safe, sanitary, and welcoming environment for your business.</span>
      </p>
    </div>
  </div>
  <div className="lg:w-1/2 order-1 lg:order-2 ">
    <img className=' rounded-md w-full h-auto max-w-3xl  lg:mx-[2%] mb-4' src={Gc} alt='Testimonial 2' />
  </div>
  </div>
  {/* BANNER START MAP */}


  <div className='p-[4%] lg:p-[2%] flex flex-col items-center'>
  <div className="lg:w-5/6 max-w-xl text-center">
    <div className=' flex justify-center'>
      <h1 className='text-[#e37200] text-4xl pb-3 font-bold underline underline-offset-4 mt-8 lg:mt-0 lg:mb-4 lg:pl-6 lg:pr-6'>
        PREVIOUS WORKSITES
      </h1>
    </div>
    
    <div className='mt-4' >
    <iframe className='w-full h-[500px]' src="https://www.google.com/maps/d/u/0/embed?mid=10reXL0vrDyOkLTlLUqYRQTvhy_HUiGM&ehbc=2E312F&noprof=1" ></iframe>
    </div>
  </div>
</div>







  {/* BANNER END MAP */}
  <div>
  <Cmi/> 
  </div>
  <div className=' bg-[#e37200] p-[4%] lg:p-[2%] opacity-95'>
    <h3 className=' p-[4%] lg:p-[2%] font-bold italic text-3xl text-center'>“… Crew is very well trained and gets the job done effectively and efficiently. 
      I have personally recommended (CMI) to others and will continue.” Tommy L., Project Superintendent 
    </h3>
  </div>
  <br></br>
  
  <div>
  <Footer/>
  </div>
  </Fade>  
</div>
  )
}

export default About