import React from 'react'
import { Link } from 'react-router-dom';
import CMI from '../Assest/Herslider/favicon.ico.jpg';
import Fade from 'react-awesome-reveal'

function Cmi() {
  return (
    <Fade cascade>
    <div className='max-w-[1240px] mx-auto gap-8'>
        
        <div className=' border-y border-black w-full flex justify-center p-4 my-2  '>
        
        <Link to='/'>
          <img className='rounded-md w-60 h-auto mx-3 my-2 lg:mx-6 lg:mt-2 hover:scale-105 duration-300 ' src={CMI} alt='CMI' />
        </Link>
        
        </div>
       
        
    </div>
    </Fade>
  )
}

export default Cmi