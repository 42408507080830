import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import CMI from '../Assest/Herslider/favicon.ico.jpg';
import Fade from 'react-awesome-reveal'

const Navbar = () => {
  const [nav, setNav] = useState(true);
  const menuRef = useRef(null);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleScrollAndResize = () => {
    // Close the menu when scrolled past 350 pixels or on large screens
    if (window.scrollY > 350 || window.innerWidth >= 768) {
      setNav(true);
    }
  };

  const handleClickOutsideMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setNav(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollAndResize);
    window.addEventListener('resize', handleScrollAndResize);
    document.addEventListener('mousedown', handleClickOutsideMenu);

    return () => {
      window.removeEventListener('scroll', handleScrollAndResize);
      window.removeEventListener('resize', handleScrollAndResize);
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, []);

  return (
    <div className='font-link  font-bold flex justify-between items-center h-24 w-[100%] mx-auto md:bg-transparent md:text-white bg-white text-black'>
      <Fade cascade>
      <div className="flex items-center">
        <Link to='/'>
          <img className='rounded-md w-36 h-auto mx-3 lg:mx-6 lg:mt-2' src={CMI} alt='CMI' />
        </Link>
        
      </div>

      <ul className={`hidden md:flex ${nav ? 'block' : 'hidden'}`} ref={menuRef}>
        <li className='hover:text-[#0066cc] hover:underline p-4'><Link to='/'> HOME </Link></li>
        <li className='hover:text-[#0066cc] hover:underline p-4'><Link to='/about'>ABOUT</Link></li>
        <li className='hover:text-[#0066cc] hover:underline p-4'><Link to='/service'>SERVICES</Link></li>
        <li className='hover:text-[#0066cc] hover:underline p-4'><Link to='/touch'>CONTACT US</Link></li>
        
      </ul>
      </Fade>
      <div onClick={handleNav} className='block md:hidden m-2 text-[#003c7a]'>
        {!nav ? <AiOutlineClose size={40} /> : <AiOutlineMenu size={40} />}
      </div>

      <div className={!nav ? 'fixed left-0 top-0 w-[60%] h-full border-r-gray-900 z-10 bg-white ease-in-out duration-700' : 'fixed left-[-100%]'}>
        <h1 className='w-full text-3xl font-bold text-orange-500 m-4'>
          <Link to='/'>
          <img className='rounded-md w-36 h-auto mx-1 lg:mx-6 lg:mt-2' src={CMI} alt='CMI' />
          </Link>
        </h1>

        <ul className='pt-4 uppercase'>
          
          <li className='p-4 border-b border-white-500'><Link to='/about'>About Us</Link></li>
          
          <li className='p-4 border-b border-white-500'><Link to = '/service'>Services</Link></li>

          <li className='p-4 border-b border-white-500'><Link to = '/touch'>Contact Us</Link></li>
          
        </ul>

      </div>

    </div>
  )
}

export default Navbar;
