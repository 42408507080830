import React, {useState} from 'react'
import { ReactTyped } from "react-typed"
import { Link } from 'react-router-dom';
import {Fade} from "react-awesome-reveal"
function Hero() {
  return (
  
    <div className='text-white font-link flex justify-center items-center h-screen'>
      <Fade 
      direction='down'>
      <div className=' mt-10 text-center'>
        {/* <p className='text-6xl md:text-8xl  mb-4'>CLEAN METRO</p> */}
        <p className='text-4xl md:text-6xl font-bold mb-4'>The Technical Cleaning Experts</p>
        <div className='flex items-center justify-center mb-4'>
          <p className='text-3xl md:text-4xl  font-bold'>Reliable - Local - Efficient</p>
          </div>
          {/* <div>
          <ReactTyped
            className='text-xl md:text-4xl font-bold ml-2'
            strings={['BTB', 'BTC']} 
            typeSpeed={280} 
            backSpeed={290} 
            loop
          />
          </div> */}
        
          <Link to='/about'><button className='font-link text-lg bg-[#e37200] w-[200px] rounded-md font-bold my-6 py-3 hover:bg-[#05305e]'>WHY US?</button></Link>
      </div>
      </Fade>
    </div>
  )
}

export default Hero