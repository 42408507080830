import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Contact from '../components/Contact'
import { Te, Tec, Tech } from '../Assest/Tcs';
import { Con, Cons, Construc } from '../Assest/Ccs';
import { Press, Pressure, Rename } from '../Assest/Wcs';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';

function Service() {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: 'ease-in-out',
    slideToShow: 1,
    slideToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className='bg-white font-link'>
      <div className='bg-[#05305e] relative z-10'>
        <Navbar />
      </div>
      <Fade>

      <div className='font-link p-[6%] lg:p-[4%]'>
        
        <h1 className='text-orange-500 text-center text-4xl pb-3 font-bold underline underline-offset-4'> Premier Construction Cleaning</h1>
        <div className='mt-8 text-3xl'>
            <p className='leading-relaxed mt-4'>
            Our premier construction cleaning services ensure your newly built or renovated spaces are pristine and ready for use. 
            We specialize in detailed post-construction cleaning, removing debris, dust, and other remnants of construction work. 
            Our team is equipped with advanced tools and techniques to handle projects of any size, ensuring every nook and cranny is spotless.
            </p>
            <br></br>
            <p className='font-bold'>Why Choose Our Construction Final Cleaning Services?</p>
            <ul className='list-disc list-outside  p-[6%]' style={{ color: '#05305e' }}>
            <li className=" mb-2 "><span className='font-semibold'>Expertise in Complex Projects:</span> We are the go-to choice for higher-risk or larger, complex projects, 
              ensuring they are handled with the utmost care and precision.</li>
              <li className=" mb-2 "><span className='font-semibold'>Thorough Cleaning:</span>  We meticulously clean all areas, including hard-to-reach spots.</li>
              <li className=" mb-2 "><span className='font-semibold'>Professional Team:</span>  Our skilled cleaners are trained to deliver top-quality results efficiently.</li>
              <li className=" mb-2 "><span className='font-semibold'>Safety First:</span>  We adhere to strict safety protocols to protect your property and our staff.</li>
            </ul>
            
            <p><Link to = '/touch'><span className='underline font-bold hover:text-[#003c7a]'>Contact us</span></Link> today to schedule your construction cleaning service and experience the Clean Metro difference.</p>
            
        </div>
      </div>
        <Slider {...settings}>
          <div>
            <img className='w-full h-auto max-w-4xl mx-auto' src={Te} alt='Testimonial 1' />
          </div>
          <div>
            <img className='w-full h-auto max-w-4xl mx-auto' src={Tec} alt='Testimonial 2' />
          </div>
          <div>
            <img className='w-full h-auto max-w-4xl mx-auto' src={Tech} alt='Testimonial 3' />
          </div>
        </Slider>


        <Fade>
        <div className='bg-[#F2F3F4]'>
        <div className='font-link p-[6%] lg:p-[4%]'>
        
          <h1 className='text-orange-500 text-center text-4xl pb-3 font-bold underline underline-offset-4'> Comprehensive Janitorial Cleaning Solutions</h1>
          <div className='mt-8 text-3xl'>
              <p className='leading-relaxed mt-4'>
              At Clean Metro Inc., we pride ourselves on delivering top-notch cleaning services to meet all your needs. 
              In addition to our renowned window cleaning and pressure washing services, we are excited to introduce our comprehensive janitorial services.
              Our professional team ensures your spaces are spotless, hygienic, and inviting, whether it's an office, commercial building, or industrial facility.
              </p>
              <br></br>
              <p className='font-bold'>Why Choose Us?</p>
              <ul className='list-disc list-outside  p-[6%]' style={{ color: '#05305e' }}>
              <li className=" mb-2 "><span className='font-semibold'>Reliability:</span> We guarantee timely and efficient services tailored to your schedule.</li>
                <li className=" mb-2 "><span className='font-semibold'>Quality:</span>  Our experienced staff uses the latest cleaning techniques and eco-friendly products to ensure the highest standards.</li>
                <li className=" mb-2 "><span className='font-semibold'>Customer-Centric Approach:</span> Your satisfaction is our priority. We customize our services to fit your specific requirements.</li>
                <li className=" mb-2 "><span className='font-semibold'>Innovative Workforce Solutions:</span> Leveraging the developing gig economy allows us to efficiently manage labor challenges, ensuring a consistent and high-quality service delivery.</li>
              </ul>
              
              <p>Experience the Clean Metro difference and let us transform your environment.  
                <Link to = '/touch'> <span className=' underline font-bold hover:text-[#003c7a]'> Reach out to us today</span></Link> by email or phone for a free consultation and discover how our janitorial services can benefit your business.</p>
              
          </div>
        </div>
        
        <Slider className=' ' {...settings}>
          <div>
            <img className='w-full h-auto max-w-4xl mx-auto' src={Con} alt='Testimonial 1' />
          </div>
          <div>
            <img className='w-full h-auto max-w-4xl mx-auto' src={Cons} alt='Testimonial 2' />
          </div>
          <div>
            <img className='w-full h-auto max-w-4xl mx-auto' src={Construc} alt='Testimonial 3' />
          </div>
        </Slider>
        </div>
        </Fade>


        <div className='font-link p-[6%] lg:p-[4%]'>
        
          <h1 className='text-orange-500 text-center text-4xl pb-3 font-bold underline underline-offset-4'> Special Cleaning Projects</h1>
          <div className='mt-8 text-3xl'>
              <p className='leading-relaxed mt-4'>
              At Clean Metro Inc., we excel in tackling unique and challenging cleaning projects that demand specialized skills and equipment. 
              Whether it’s delicate surface restoration, high-level cleaning, or any other specialized task, our expert team handles it with precision and expertise.
              </p>
              <br></br>
              <p className='font-bold'>Why Choose Us for Special Cleaning Projects?</p>
              <ul className='list-disc list-outside  p-[6%]' style={{ color: '#05305e' }}>
              <li className=" mb-2 "><span className='font-semibold'>Industry Leader:</span> We lead the way in cleaning innovations.</li>
                <li className=" mb-2 "><span className='font-semibold'>Specialized Team:</span>  Our experts master complex, out-of-scope projects.</li>
                <li className=" mb-2 "><span className='font-semibold'>Diverse Expertise:</span>  Skilled in overhead cleaning, combustible dust containment, detailed machine cleaning, professional glass cleaning.</li>
                <li className=" mb-2 "><span className='font-semibold'>Customized Experience:</span>  Our experience in various industries helps us tailor the customer experience to different environments.</li>
              </ul>
              
              {/* <p>Experience the Clean Metro difference and let us transform your environment.  
                <Link to = '/touch'> <span className=' underline font-bold hover:text-[#003c7a]'> Reach out to us today</span></Link> by email or phone for a free consultation and discover how our janitorial services can benefit your business.</p> */}
              
          </div>
        </div>
        <Slider {...settings} className='my-4'>
          <div>
            <img className='w-full h-auto max-w-4xl mx-auto' src={Press} alt='Testimonial 1' />
          </div>
          <div>
            <img className='w-full h-auto max-w-4xl mx-auto' src={Pressure} alt='Testimonial 2' />
          </div>
          <div>
            <img className='w-full h-auto max-w-4xl mx-auto' src={Rename} alt='Testimonial 3' />
          </div>
        </Slider>

      </Fade>
      <div className='bg-[#F2F3F4] p-[6%] lg:p-[4%]'><Contact/></div>
      
      <Footer />
    </div>
  );
}

export default Service;
