import React, { useState } from 'react';
import Video from '../Assest/Herslider/viccmi1.mp4';
import PlaceholderImage from '../Assest/Aboutimg/groupa.jpg'; // Import your placeholder image
import Hero from './Hero';
import Navbar from './Navbar';

function Home() {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  return (
    <div className='relative top-0  h-screen overflow-hidden bg-black'>
      { !videoLoaded && <img className='absolute inset-0 w-full h-full object-cover z-5' src={PlaceholderImage} alt="Placeholder" /> }

      <video
        className={`aspect-video block absolute inset-0 w-full h-full object-cover z-5 opacity-${videoLoaded ? '100' : '0'}`}
        src={Video}
        playsInline
        autoPlay
        loop
        muted
        onLoadedData={handleVideoLoad}
      ></video>

      <div className='relative z-10'>
        <Navbar />
        <Hero />
      </div>
      <div>
        {/* Other components */}
      </div>
    </div>
  );
}

export default Home;
